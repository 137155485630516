import * as CONST from '../ActionTypes';

const initialState = {
  deliveryRequestList: [],
  deliveryRequestQuetionNaires: [],
  suggestionList: [],
  deliveryRequest: {},
  isSuccess: false,
  isFailure: false,
  isSuccessTemp: false,
  isFailureTemp: false,
  isUpdateSuccess: false,
  isUpdateFailure: false,
  isSuggestionSuccess: false,
  isSuggestionFailure: false,
  errorMessage: '',
  isApprovedSuccess: false,
  isApprovedFailure: false,
  isQuestionSuccess: false,
  isQuestionFailure: false,
  numberMatchingRequest: 0,
  isUpdateStatusSuccess: false,
  isUpdateStatusFailure: false,
};

function deliveryRequest(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 見積依頼履歴詳細取得
    // -----------------
    case CONST.GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_SUCCESS:
      return {
        ...state,
        deliveryRequest: action.payload,
      };
    case CONST.GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_FAILURE:
      return {
        ...state,
        deliveryRequest: {},
      };
    // -----------------
    // 見積依頼一覧取得
    // -----------------
    case CONST.GET_LIST_DELIVERY_REQUEST_CUSTOMER_SUCCESS:
      return {
        ...state,
        deliveryRequestList: action.payload.result,
        totalPage: action.payload.total_page,
        offset: action.payload.offset,
        page: action.payload.page,
        limit: action.payload.limit,
        totalCount: action.payload.total_count,
        totalPosted: action.payload.total_posted,
        totalSelected: action.payload.total_selected,
        totalCompleted: action.payload.total_completed,
        totalCancelled: action.payload.total_cancelled,
        isUpdateStatusSuccess: false,
      };
    case CONST.GET_LIST_DELIVERY_REQUEST_CUSTOMER_FAILURE:
      return {
        ...state,
        deliveryRequestList: [],
      };
    // -----------------
    // 提案一覧取得
    // -----------------
    case CONST.GET_LIST_SUGGUESTIONS_CUSTOMER_SUCCESS:
      return {
        ...state,
        suggestionList: action.payload.result,
        totalPage: action.payload.total_page,
        offset: action.payload.offset,
        page: action.payload.page,
        limit: action.payload.limit,
        totalCount: action.payload.total_count,
        totalApporved: action.payload.total_apporved,
        totalNotApporved: action.payload.total_not_apporved,
        totalReject: action.payload.total_is_reject,
        totalProgress: action.payload.total_progress,
        totalNewSuggestion: action.payload.total_new_suggestion,
        totalCanceled: action.payload.total_canceled,
      };
    case CONST.GET_LIST_SUGGUESTIONS_CUSTOMER_FAILURE:
      return {
        ...state,
        suggestionList: [],
      };
      // -----------------
    // 提案を得る
    // -----------------
    case CONST.GET_SUGGUESTIONS_CUSTOMER_SUCCESS:
      return {
        ...state,
        suggestion: action.payload,
      };
    case CONST.GET_SUGGUESTIONS_CUSTOMER_FAILURE:
      return {
        ...state,
        suggestion: [],
      };

    // -----------------
    // 見積依頼履歴詳細取得
    // -----------------
    case CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_SUCCESS:
      return {
        ...state,
        deliveryRequest: action.payload,
      };
    case CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_FAILURE:
      return {
        ...state,
        deliveryRequest: {},
      };
    // -----------------
    // 見積依頼一覧取得
    // -----------------
    case CONST.GET_LIST_DELIVERY_REQUEST_DRIVER_SUCCESS:
      return {
        ...state,
        deliveryRequestList: action.payload.result,
        offset: action.payload.offset,
        totalPage: action.payload.total_page,
        page: action.payload.page,
        limit: action.payload.limit,
        totalCount: action.payload.total_count,
      };
    case CONST.GET_LIST_DELIVERY_REQUEST_DRIVER_FAILURE:
      return {
        ...state,
        deliveryRequestList: [],
      };

    // -----------------
    // 配達要求の質問票を取得する顧客
    // -----------------
    case CONST.GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS:
      return {
        ...state,
        deliveryRequestQuetionNaires: action.payload,
      };
    case CONST.GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE:
      return {
        ...state,
        deliveryRequestQuetionNaires: [],
      };

    // -----------------
    // 見積依頼投稿
    // -----------------
    case CONST.CREATE_DELIVERY_REQUEST_CUSTOMER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isFailure: false,
        isPostSuccess: true,
        errorMessage: '',
      };
    case CONST.CREATE_DELIVERY_REQUEST_CUSTOMER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isFailure: true,
        errorMessage: action.payload,
      };
    case CONST.CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER_FAILURE:
      return {
        ...state,
        isSuccessTemp: false,
        isFailureTemp: true,
        errorMessage: action.payload,
      };
    case CONST.UPDATE_DELIVERY_REQUEST_CUSTOMER_FAILURE:
      return {
        ...state,
        isUpdateSuccess: false,
        isUpdateFailure: true,
        errorMessage: action.payload,
      };
    case CONST.CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS:
      return {
        ...state,
        isQuestionSuccess: true,
        isQuestionFailure: false,
        isPostSuccess: true,
        errorMessage: '',
      };
    case CONST.CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isFailure: true,
        errorMessage: action.payload,
      };
    case CONST.UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_SUCCESS:
      return {
        ...state,
        isUpdateStatusSuccess: true,
        isUpdateStatusFailure: false,
        errorMessage: '',
      };
    case CONST.UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isFailure: true,
        errorMessage: action.payload,
      };
    // -----------------
    // CLEAR
    // -----------------
    case CONST.CLEAR_RESULT_CREATE_DELIVERY_REQUEST_CUSTOMER:
      return {
        ...state,
        isSuccess: false,
        isFailure: false,
        isPostSuccess: false,
        errorMessage: '',
      };
    case CONST.CLEAR_RESULT_CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER:
      return {
        ...state,
        isSuccessTemp: false,
        isFailureTemp: false,
        errorMessage: '',
      };
    case CONST.GET_LIST_SUGGESTION_DRIVER_SUCCESS:
      return {
        ...state,
        deliveryRequestList: action.payload.result,
        offset: action.payload.offset,
        page: action.payload.page,
        limit: action.payload.limit,
        totalCount: action.payload.total_count,
        totalPage: action.payload.total_page,
        totalApporved: action.payload.total_apporved,
        totalNotApporved: action.payload.total_not_apporved,
        totalReject: action.payload.total_is_reject,
        totalProgress: action.payload.total_progress,
        totalNewSuggestion: action.payload.total_new_suggestion,
        totalDetermined: action.payload.total_determined,
        totalCanceled: action.payload.total_canceled,
      };
    case CONST.GET_LIST_SUGGESTION_DRIVER_FAILURE:
      return {
        ...state,
        deliveryRequestList: [],
      };
    case CONST.POST_SUGGESTION_DRIVER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuggestionSuccess: true,
        isSuggestionFailure: false,
      };
    case CONST.POST_SUGGESTION_DRIVER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuggestionSuccess: false,
        isSuggestionFailure: true,
      };
    case CONST.CLEAR_POST_SUGGESTION_RESULT_DRIVER:
      return {
        ...state,
        errorMessage: '',
        isSuggestionSuccess: false,
        isSuggestionFailure: false,
      };
    case CONST.EDIT_SUGGESTION_DRIVER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuggestionSuccess: true,
        isSuggestionFailure: false,
      };
    case CONST.EDIT_SUGGESTION_DRIVER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuggestionSuccess: false,
        isSuggestionFailure: true,
      };
    case CONST.CLEAR_EDIT_SUGGESTION_RESULT_DRIVER:
      return {
        ...state,
        errorMessage: '',
        isSuggestionSuccess: false,
        isSuggestionFailure: false,
      };
    case CONST.APPROVE_SUGGESTION_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isApprovedSuccess: true,
        isSuggestionFailure: false,
      };
    case CONST.APPROVE_SUGGESTION_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        errorMessage: '',
        isApprovedSuccess: false,
        isSuggestionFailure: true,
      };
    case CONST.REJECT_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isRejectSuccess: true,
      };
    case CONST.CLEAR_APPROVE_SUGGESTION_BY_CUSTOMER:
      return {
        ...state,
        errorMessage: '',
        isApprovedSuccess: false,
        isSuggestionFailure: false,
        isPostSuccess: false,
        isRejectSuccess: false,
        isQuestionFailure: false,
        isQuestionSuccess: false,
      };
    case CONST.READ_AT_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isUpdateReadAt: true,
        isUpdateReadAtFailure: false,
      };
    case CONST.READ_AT_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        errorMessage: '',
        isUpdateReadAt: false,
        isUpdateReadAtFailure: true,
      };
    case CONST.GET_MATCHING_REQUEST_SUCCESS:
      return {
        ...state,
        numberMatchingRequest: action.payload.number_matching_request,
      };
    case CONST.GET_MATCHING_REQUEST_FAILURE:
      return {
        ...state,
        numberMatchingRequest: 0,
      };
    default:
      return state;
  }
}

export default deliveryRequest;
