import * as CONST from '../ActionTypes';

const initialState = {
  notificationDetail: {},
  notificationList: [],
  notificationTopList: [],
  errorMessage: '',
  notifications: '',
  isRemoveRelSuccess: false,
};

function notifications(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_LIST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationList: action.payload.result,
        notificationTopList: action.payload,
        offset: action.payload.offset,
        limit: action.payload.limit,
        totalPage: action.payload.total_page,
        page: action.payload.page,
        totalCount: action.payload.total_count,
        isRemoveRelSuccess: false,
      };
    case CONST.GET_LIST_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationList: [],
      };
    case CONST.CLEAR_LIST_NOTIFICATIONS:
      return {
        ...state,
        notificationList: [],
      };
    case CONST.GET_DETAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload,
      };
    case CONST.GET_DETAIL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationDetail: null,
      };
    case CONST.GET_DETAIL_NOTIFICATIONS_TOP_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case CONST.GET_LIST_NOTIFICATIONS_TOP_FAILURE:
      return {
        ...state,
        notificationDetail: null,
      };
    case CONST.REMOVE_BULLETIN_BOARD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.err,
      };
    case CONST.REMOVE_BULLETIN_BOARD_RELY_SUCCESS:
      return {
        ...state,
        isRemoveRelSuccess: true,
      };
    case CONST.REMOVE_BULLETIN_BOARD_RELY_FAILURE:
      return {
        ...state,
        isRemoveRelSuccess: false,
      };
    default:
      return state;
  }
}
export default notifications;
