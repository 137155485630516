import * as CONST from '../ActionTypes';

const initialState = {
  checkBadges: {},
  errorMessage: '',
};

function checkBadges(state = initialState, action) {
  switch (action.type) {
    case CONST.CUSTOMER_CHECK_BADGES_SUCCESS:
      return {
        ...state,
        checkBadges: action.payload,
      };
    case CONST.CUSTOMER_CHECK_BADGES_FAILURE:
      return {
        ...state,
        checkBadges: [],
      };
    case CONST.DRIVER_CHECK_BADGES_SUCCESS:
      return {
        ...state,
        checkBadges: action.payload,
      };
    case CONST.DRIVER_CHECK_BADGES_FAILURE:
      return {
        ...state,
        checkBadges: [],
      };
    case CONST.CLEAR_CHECK_BADGES:
      return {
        ...state,
        errorMessage: '',
        isSuccess: false,
        isFailure: false,
      };
    default:
      return state;
  }
}
export default checkBadges;
