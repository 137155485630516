import * as CONST from '../ActionTypes';

const initialState = {
  loading: false,
};

function loading(state = initialState, action) {
  switch (action.type) {
    case CONST.LOADING:
      return {
        ...state,
        loading: true,
      };
    case CONST.UNLOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export default loading;
