import { combineReducers } from 'redux';
import authCustomer from './customer/auth';
import authDriver from './driver/auth';
import deliveryRequest from './deliveryRequest';
import loading from './loading';
import customer from './customer/customer';
import driver from './driver/driver';
import prefecture from './prefecture';
import role from './role';
import driverCustomer from './customer/driver';
import qa from './qa';
import notifications from './notifications';
import contact from './contact';
import content from './content';
import checkBadge from './checkBadges';

export default combineReducers({
  authCustomer,
  authDriver,
  loading,
  deliveryRequest,
  customer,
  driver,
  prefecture,
  role,
  driverCustomer,
  qa,
  notifications,
  contact,
  content,
  checkBadge,
});
