import * as CONST from '../../ActionTypes';

const initialState = {
  driver: {},
  isSuccess: false,
  isFailure: false,
  errorMessage: '',
};

function driverCustomer(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 物流業者詳細取得
    // -----------------
    case CONST.GET_DRIVER_CUSTOMER_SUCCESS:
      return {
        ...state,
        driver: action.payload,
        isSuccess: true,
        isFailure: false,
      };
    case CONST.GET_DRIVER_CUSTOMER_FAILURE:
      return {
        ...state,
        driver: {},
        isSuccess: false,
        isFailure: true,
      };

    default:
      return state;
  }
}

export default driverCustomer;
