import * as CONST from '../ActionTypes';

const initialState = {
  isSuccess: false,
  isSuccessContact: false,
  isFailure: false,
  errorMessage: '',
};

function contact(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 結果フラグクリア
    // -----------------
    case CONST.SEND_CONTACT_SUCCESS:
      return {
        ...state,
        isSuccessContact: true,
        isFailure: false,
        errorMessage: '',
      };
    case CONST.SEND_CONTACT_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccessContact: false,
        isFailure: true,
      };
    case CONST.SEND_CONTACT_CLEAR:
      return {
        ...state,
        errorMessage: '',
        isSuccessContact: false,
        isFailure: false,
      };

    default:
      return state;
  }
}

export default contact;
