import * as CONST from '../ActionTypes';

const initialState = {
  prefList: [],
};

function prefecture(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 都道府県一覧取得
    // -----------------
    case CONST.GET_PREF_LIST_SUCCESS:
      return {
        ...state,
        prefList: action.payload,
      };
    case CONST.GET_PREF_LIST_FAILURE:
      return {
        ...state,
        prefList: [],
      };
    default:
      return state;
  }
}

export default prefecture;
