import * as CONST from '../../ActionTypes';
import Company from '../../../views/company/Company';

const initialState = {
  isLoggedIn: false,
  errorMessage: '',
  authLoading: true,
  accessToken: '',
  driverInfo: {},
  isRegisterSuccess: false,
  isSuccess: false,
  isSuccessSendPW: false,
  isFailureSendPW: false,
  isManager: false,
  CompanyName: '',
};

function authDriver(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // ログイン
    // -----------------
    case CONST.LOGIN_DRIVER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        errorMessage: '',
        accessToken: action.payload.access_token,
        isManager: action.payload.is_manager,
        driverInfo: {
          id: action.payload.id,
          email: action.payload.email,
          familyName: action.payload.family_name,
          firstName: action.payload.first_name,
          familyNameKana: action.payload.family_name_kana,
          firstNameKana: action.payload.first_name_kana,
          profile: action.payload.profile,
          createdAt: action.payload.created_at,
          updatedAt: action.payload.updated_at,
          CompanyName: action.payload?.company_name,
        },
      };
    case CONST.LOGIN_DRIVER_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: 'Eメールかパスワードが正しくありません',
        accessToken: '',
        driverInfo: {},
      };
    // -----------------
    // リフレッシュトークンでログイン
    // -----------------
    case CONST.REFRESH_TOKEN_DRIVER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        errorMessage: '',
        authLoading: false,
        accessToken: action.payload.access_token,
        isManager: action.payload.is_manager,
        driverInfo: {
          id: action.payload.id,
          email: action.payload.email,
          familyName: action.payload.family_name,
          firstName: action.payload.first_name,
          familyNameKana: action.payload.family_name_kana,
          firstNameKana: action.payload.first_name_kana,
          profile: action.payload.profile,
          createdAt: action.payload.created_at,
          updatedAt: action.payload.updated_at,
          CompanyName: action.payload.company_name,
        },
      };
    case CONST.REFRESH_TOKEN_DRIVER_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: '',
        authLoading: false,
        accessToken: '',
        driverInfo: {},
      };
    // -----------------
    // 登録
    // -----------------
    case CONST.REGISTER_DRIVER_SUCCESS:
      return {
        ...state,
        isRegisterSuccess: true,
        errorMessage: '',
      };
    case CONST.REGISTER_DRIVER_FAILURE:
      return {
        ...state,
        isRegisterSuccess: false,
        errorMessage: action.payload,
      };
    // -----------------
    // パスワード変更
    // -----------------
    case CONST.CHANGE_PASSWORD_DRIVER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        errorMessage: '',
      };
    case CONST.CHANGE_PASSWORD_DRIVER_FAILURE:
      return {
        ...state,
        isSuccessSendPW: false,
        errorMessage: action.payload,
      };
    // -----------------
    // パスワードリセット送信
    // -----------------
    case CONST.SEND_RESET_PASSWORD_DRIVER_SUCCESS:
      return {
        ...state,
        isSuccessSendPW: true,
        errorMessage: '',
      };
    case CONST.SEND_RESET_PASSWORD_DRIVER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        errorMessage: action.payload,
        isFailureSendPW: true,
      };
    // -----------------
    // ログアウト
    // -----------------
    case CONST.DRIVER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: '',
        accessToken: '',
        driverInfo: {},
      };
    // -----------------
    // 結果クリア
    // -----------------
    case CONST.CLEAR_RESULT_DRIVER:
      return {
        ...state,
        isSuccess: false,
        errorMessage: '',
        isSuccessSendPW: false,
        isFailureSendPW: false,
      };

    default:
      return state;
  }
}

export default authDriver;
