import * as CONST from '../../ActionTypes';

const initialState = {
  isLoggedIn: false,
  errorMessage: '',
  authLoading: true,
  accessToken: '',
  customerInfo: {},
  isRegisterSuccess: false,
  isSuccess: false,
  isSuccessSendPW: false,
  isFailureSendPW: false,
};

function authCustomer(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // ログイン
    // -----------------
    case CONST.LOGIN_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        errorMessage: '',
        accessToken: action.payload.access_token,
        customerInfo: {
          id: action.payload.id,
          email: action.payload.email,
          familyName: action.payload.family_name,
          firstName: action.payload.first_name,
          familyNameKana: action.payload.family_name_kana,
          firstNameKana: action.payload.first_name_kana,
          profile: action.payload.profile,
          createdAt: action.payload.created_at,
          updatedAt: action.payload.updated_at,
        },
      };
    case CONST.LOGIN_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: 'Eメールかパスワードが正しくありません',
        accessToken: '',
        customerInfo: {},
      };
    // -----------------
    // リフレッシュトークンでログイン
    // -----------------
    case CONST.REFRESH_TOKEN_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        errorMessage: '',
        authLoading: false,
        accessToken: action.payload.access_token,
        customerInfo: {
          id: action.payload.id,
          email: action.payload.email,
          familyName: action.payload.family_name,
          firstName: action.payload.first_name,
          familyNameKana: action.payload.family_name_kana,
          firstNameKana: action.payload.first_name_kana,
          profile: action.payload.profile,
          createdAt: action.payload.created_at,
          updatedAt: action.payload.updated_at,
        },
      };
    case CONST.REFRESH_TOKEN_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: '',
        authLoading: false,
        accessToken: '',
        customerInfo: {},
      };
    // -----------------
    // 登録
    // -----------------
    case CONST.REGISTER_CUSTOMER_SUCCESS:
      return {
        ...state,
        isRegisterSuccess: true,
        errorMessage: '',
      };
    case CONST.REGISTER_CUSTOMER_FAILURE:
      return {
        ...state,
        isRegisterSuccess: false,
        errorMessage: action.payload,
      };
    // -----------------
    // パスワード変更
    // -----------------
    case CONST.CHANGE_PASSWORD_CUSTOMER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        errorMessage: '',
      };
    case CONST.CHANGE_PASSWORD_CUSTOMER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        errorMessage: action.payload,
      };
    // -----------------
    // パスワードリセット送信
    // -----------------
    case CONST.SEND_RESET_PASSWORD_CUSTOMER_SUCCESS:
      return {
        ...state,
        isSuccessSendPW: true,
        errorMessage: '',
      };
    case CONST.SEND_RESET_PASSWORD_CUSTOMER_FAILURE:
      return {
        ...state,
        isSuccessSendPW: false,
        errorMessage: action.payload,
      };
    // -----------------
    // ログアウト
    // -----------------
    case CONST.CUSTOMER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: '',
        accessToken: '',
        customerInfo: {},
      };
    // -----------------
    // 結果クリア
    // -----------------
    case CONST.CLEAR_RESULT_CUSTOMER:
      return {
        ...state,
        isSuccess: false,
        errorMessage: '',
        isSuccessSendPW: false,
        isFailureSendPW: false,
      };

    default:
      return state;
  }
}

export default authCustomer;
