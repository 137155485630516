import * as CONST from '../../ActionTypes';

const initialState = {
  errorMessage: '',
  isSuccess: false,
  isFailure: false,
  isDisableSuccess: false,
  isDisableFailure: false,
  memberList: [],
};

function driver(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 荷主プロフィール編集
    // -----------------
    case CONST.UPDATE_PROFILE_DRIVER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccess: true,
        isFailure: false,
      };
    case CONST.UPDATE_PROFILE_DRIVER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccess: false,
        isFailure: true,
      };
    case CONST.CLEAR_MESSAGE_DRIVER:
      return {
        ...state,
        errorMessage: '',
      };
    case CONST.UPDATE_EMAIL_DRIVER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccess: true,
        isFailure: false,
      };
    case CONST.UPDATE_EMAIL_DRIVER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccess: false,
        isFailure: true,
      };
    case CONST.VERIFY_TOKEN_DRIVER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccess: true,
        isFailure: false,
      };
    case CONST.VERIFY_TOKEN_DRIVER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccess: false,
        isFailure: true,
      };
    case CONST.CLEAR_DRIVER:
      return {
        ...state,
        isSuccess: false,
        isFailure: false,
        errorMessage: '',
      };
    case CONST.GET_LIST_DRIVER_MEMBER_SUCCESS:
      return {
        ...state,
        memberList: action.payload.result,
        totalPage: action.payload.total_page,
        offset: action.payload.offset,
        page: action.payload.page,
        limit: action.payload.limit,
      };
    case CONST.GET_LIST_DRIVER_MEMBER_FAILURE:
      return {
        ...state,
        memberList: [],
      };
    case CONST.DISABLE_DRIVER_MEMBER_SUCCESS:
      return {
        ...state,
        isDisableSuccess: true,
        isDisableFailure: false,
        errorMessage: '',
      };
    case CONST.DISABLE_DRIVER_MEMBER_FAILURE:
      return {
        ...state,
        isDisableSuccess: false,
        isDisableFailure: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default driver;
