import * as CONST from '../ActionTypes';

const initialState = {
  isSuccess: false,
  isFailure: false,
  errorMessage: '',
};

function qa(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 事前質問
    // -----------------
    case CONST.POST_ANSWER_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccess: true,
        isFailure: false,
      };
    case CONST.POST_ANSWER_CUSTOMER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccess: false,
        isFailure: true,
      };
    case CONST.CLEAR_POST_ANSWER_RESULT_CUSTOMER:
      return {
        ...state,
        errorMessage: '',
        isSuccess: false,
        isFailure: false,
      };
    // -----------------
    // 事前質問回答
    // -----------------
    case CONST.POST_QUESTION_DRIVER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccess: true,
        isFailure: false,
      };
    case CONST.POST_QUESTION_DRIVER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccess: false,
        isFailure: true,
      };
    case CONST.CLEAR_POST_QUESTION_RESULT_DRIVER:
      return {
        ...state,
        errorMessage: '',
        isSuccess: false,
        isFailure: false,
      };

    default:
      return state;
  }
}

export default qa;
