import * as CONST from '../../ActionTypes';

const initialState = {
  errorMessage: '',
  isSuccess: false,
  isSuccessEmail: false,
  isFailure: false,
};

function customer(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 荷主プロフィール編集
    // -----------------
    case CONST.UPDATE_PROFILE_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccess: true,
        isFailure: false,
      };
    case CONST.UPDATE_PROFILE_CUSTOMER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccess: false,
        isFailure: true,
      };
    case CONST.UPDATE_EMAIL_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccessEmail: true,
        isFailure: false,
      };
    case CONST.UPDATE_EMAIL_CUSTOMER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccessEmail: false,
        isFailure: true,
      };
    case CONST.VERIFY_TOKEN_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isSuccess: true,
        isFailure: false,
      };
    case CONST.VERIFY_TOKEN_CUSTOMER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSuccess: false,
        isFailure: true,
      };
    case CONST.CLEAR_MESSAGE_CUSTOMER:
      return {
        ...state,
        errorMessage: '',
        isSuccess: false,
      };
    case CONST.CLEAR_CUSTOMER:
      return {
        ...state,
        isSuccess: false,
        isFailure: false,
        errorMessage: '',
      };
    default:
      return state;
  }
}

export default customer;
