import * as CONST from '../ActionTypes';

const initialState = {
  contentDetail: {},
  contentList: [],
  errorMessage: '',
  memberSupportingList: [],
};

function content(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_LIST_CONTENT_SUCCESS:
      return {
        ...state,
        contentList: action.payload.result,
        offset: action.payload.offset,
        limit: action.payload.limit,
        totalPage: action.payload.total_page,
        page: action.payload.page,
        totalCount: action.payload.total_count,
      };
    case CONST.GET_LIST_CONTENT_FAILURE:
      return {
        ...state,
        contentList: [],
      };
    case CONST.GET_DETAIL_CONTENT_SUCCESS:
      return {
        ...state,
        contentDetail: action.payload,
      };
    case CONST.GET_DETAIL_CONTENT_FAILURE:
      return {
        ...state,
        contentDetail: null,
      };
    case CONST.GET_LIST_MEMBER_SUPPORTING_SUCCESS:
      return {
        ...state,
        memberSupportingList: action.payload.result,
        offset: action.payload.offset,
        limit: action.payload.limit,
        totalPage: action.payload.total_page,
        page: action.payload.page,
        totalCount: action.payload.total_count,
      };
    case CONST.GET_LIST_MEMBER_SUPPORTING_FAILURE:
      return {
        ...state,
        memberSupportingList: [],
      };
    case CONST.CLEAR_LIST_SUPPORTING:
      return {
        ...state,
        memberSupportingList: [],
        contentList: [],
      };
    default:
      return state;
  }
}
export default content;
