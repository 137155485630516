import * as CONST from '../ActionTypes';

const initialState = {
  role: 'customer',
};

function role(state = initialState, action) {
  switch (action.type) {
    case CONST.CHANGE_ROLE:
      return {
        ...state,
        role: action.payload,
      };

    default:
      return state;
  }
}

export default role;
