// loading
export const LOADING = 'LOADING';
export const UNLOADING = 'UNLOADING';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_UNLOADING = 'UNLOADING';

// auth customer
export const LOGIN_CUSTOMER_SUCCESS = 'LOGIN_CUSTOMER_SUCCESS';
export const LOGIN_CUSTOMER_FAILURE = 'LOGIN_CUSTOMER_FAILURE';
export const REFRESH_TOKEN_CUSTOMER_SUCCESS = 'REFRESH_TOKEN_CUSTOMER_SUCCESS';
export const REFRESH_TOKEN_CUSTOMER_FAILURE = 'REFRESH_TOKEN_CUSTOMER_FAILURE';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const REGISTER_CUSTOMER_FAILURE = 'REGISTER_CUSTOMER_FAILURE';
export const CHANGE_PASSWORD_CUSTOMER_SUCCESS = 'CHANGE_PASSWORD_CUSTOMER_SUCCESS';
export const CHANGE_PASSWORD_CUSTOMER_FAILURE = 'CHANGE_PASSWORD_CUSTOMER_FAILURE';
export const SEND_RESET_PASSWORD_CUSTOMER_SUCCESS = 'SEND_RESET_PASSWORD_CUSTOMER_SUCCESS';
export const SEND_RESET_PASSWORD_CUSTOMER_FAILURE = 'SEND_RESET_PASSWORD_CUSTOMER_FAILURE';
export const CUSTOMER_LOGOUT = 'CUSTOMER_LOGOUT';
export const CLEAR_RESULT_CUSTOMER = 'CLEAR_RESULT_CUSTOMER';
export const CLEAR_MESSAGE_CUSTOMER = 'CLEAR_MESSAGE_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
// auth driver
export const LOGIN_DRIVER_SUCCESS = 'LOGIN_DRIVER_SUCCESS';
export const LOGIN_DRIVER_FAILURE = 'LOGIN_DRIVER_FAILURE';
export const REFRESH_TOKEN_DRIVER_SUCCESS = 'REFRESH_TOKEN_DRIVER_SUCCESS';
export const REFRESH_TOKEN_DRIVER_FAILURE = 'REFRESH_TOKEN_DRIVER_FAILURE';
export const REGISTER_DRIVER_SUCCESS = 'REGISTER_DRIVER_SUCCESS';
export const REGISTER_DRIVER_FAILURE = 'REGISTER_DRIVER_FAILURE';
export const CHANGE_PASSWORD_DRIVER_SUCCESS = 'CHANGE_PASSWORD_DRIVER_SUCCESS';
export const CHANGE_PASSWORD_DRIVER_FAILURE = 'CHANGE_PASSWORD_DRIVER_FAILURE';
export const SEND_RESET_PASSWORD_DRIVER_SUCCESS = 'SEND_RESET_PASSWORD_DRIVER_SUCCESS';
export const SEND_RESET_PASSWORD_DRIVER_FAILURE = 'SEND_RESET_PASSWORD_DRIVER_FAILURE';
export const DRIVER_LOGOUT = 'DRIVER_LOGOUT';
export const CLEAR_RESULT_DRIVER = 'CLEAR_RESULT_DRIVER';
export const CLEAR_MESSAGE_DRIVER = 'CLEAR_MESSAGE_DRIVER';
export const CLEAR_DRIVER = 'CLEAR_DRIVER';

// delivery customer
export const GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_SUCCESS = 'GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_SUCCESS';
export const GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_FAILURE = 'GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_FAILURE';
export const GET_LIST_DELIVERY_REQUEST_CUSTOMER_SUCCESS = 'GET_LIST_DELIVERY_REQUEST_CUSTOMER_SUCCESS';
export const GET_LIST_DELIVERY_REQUEST_CUSTOMER_FAILURE = 'GET_LIST_DELIVERY_REQUEST_CUSTOMER_FAILURE';
export const GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS = 'GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS';
export const GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE = 'GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE';
export const CREATE_DELIVERY_REQUEST_CUSTOMER_SUCCESS = 'CREATE_DELIVERY_REQUEST_CUSTOMER_SUCCESS';
export const CREATE_DELIVERY_REQUEST_CUSTOMER_FAILURE = 'CREATE_DELIVERY_REQUEST_CUSTOMER_FAILURE';
export const CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER_FAILURE = 'CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER_FAILURE';
export const CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS = 'CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS';
export const CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE = 'CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE';
export const UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_SUCCESS = 'UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_SUCCESS';
export const UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_FAILURE = 'UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_FAILURE';
export const CLEAR_RESULT_CREATE_DELIVERY_REQUEST_CUSTOMER = 'CLEAR_RESULT_CREATE_DELIVERY_REQUEST_CUSTOMER';
export const CLEAR_RESULT_CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER = 'CLEAR_RESULT_CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER';
export const APPROVE_SUGGESTION_BY_CUSTOMER_SUCCESS = 'APPROVE_SUGGESTION_BY_CUSTOMER_SUCCESS';
export const APPROVE_SUGGESTION_BY_CUSTOMER_FAILURE = 'APPROVE_SUGGESTION_BY_CUSTOMER_FAILURE';
export const CLEAR_APPROVE_SUGGESTION_BY_CUSTOMER = 'CLEAR_APPROVE_SUGGESTION_BY_CUSTOMER';
export const REJECT_SUGGESTION_BY_CUSTOMER_SUCCESS = 'REJECT_SUGGESTION_BY_CUSTOMER_SUCCESS';
export const REJECT_SUGGESTION_BY_CUSTOMER_FAILURE = 'REJECT_SUGGESTION_BY_CUSTOMER_FAILURE';
export const REJECT_BY_CUSTOMER_SUCCESS = 'REJECT_BY_CUSTOMER_SUCCESS';
export const READ_AT_BY_CUSTOMER_SUCCESS = 'READ_AT_BY_CUSTOMER_SUCCESS';
export const READ_AT_BY_CUSTOMER_FAILURE = 'READ_AT_BY_CUSTOMER_FAILURE';
export const UPDATE_DELIVERY_REQUEST_CUSTOMER_FAILURE = 'UPDATE_DELIVERY_REQUEST_CUSTOMER_FAILURE';
export const GET_MATCHING_REQUEST_SUCCESS = 'GET_MATCHING_REQUEST_SUCCESS';
export const GET_MATCHING_REQUEST_FAILURE = 'GET_MATCHING_REQUEST_FAILURE';

// sugguestion customer
export const GET_LIST_SUGGUESTIONS_CUSTOMER_SUCCESS = 'GET_LIST_SUGGUESTIONS_CUSTOMER_SUCCESS';
export const GET_LIST_SUGGUESTIONS_CUSTOMER_FAILURE = 'GET_LIST_SUGGUESTIONS_CUSTOMER_FAILURE';
export const GET_SUGGUESTIONS_CUSTOMER_SUCCESS = 'GET_SUGGUESTIONS_CUSTOMER_SUCCESS';
export const GET_SUGGUESTIONS_CUSTOMER_FAILURE = 'GET_SUGGUESTIONS_CUSTOMER_FAILURE';

// delivery driver
export const GET_DETAIL_DELIVERY_REQUEST_DRIVER_SUCCESS = 'GET_DETAIL_DELIVERY_REQUEST_DRIVER_SUCCESS';
export const GET_DETAIL_DELIVERY_REQUEST_DRIVER_FAILURE = 'GET_DETAIL_DELIVERY_REQUEST_DRIVER_FAILURE';
export const GET_LIST_DELIVERY_REQUEST_DRIVER_SUCCESS = 'GET_LIST_DELIVERY_REQUEST_DRIVER_SUCCESS';
export const GET_LIST_DELIVERY_REQUEST_DRIVER_FAILURE = 'GET_LIST_DELIVERY_REQUEST_DRIVER_FAILURE';
export const GET_LIST_SUGGESTION_DRIVER_SUCCESS = 'GET_LIST_SUGGESTION_DRIVER_SUCCESS';
export const GET_LIST_SUGGESTION_DRIVER_FAILURE = 'GET_LIST_SUGGESTION_DRIVER_FAILURE';

// customer
export const UPDATE_PROFILE_CUSTOMER_SUCCESS = 'UPDATE_PROFILE_CUSTOMER_SUCCESS';
export const UPDATE_PROFILE_CUSTOMER_FAILURE = 'UPDATE_PROFILE_CUSTOMER_FAILURE';
export const GET_DRIVER_CUSTOMER_SUCCESS = 'GET_DRIVER_CUSTOMER_SUCCESS';
export const GET_DRIVER_CUSTOMER_FAILURE = 'GET_DRIVER_CUSTOMER_FAILURE';
export const POST_ANSWER_CUSTOMER_SUCCESS = 'POST_ANSWER_CUSTOMER_SUCCESS';
export const POST_ANSWER_CUSTOMER_FAILURE = 'POST_ANSWER_CUSTOMER_FAILURE';
export const CLEAR_POST_ANSWER_RESULT_CUSTOMER = 'CLEAR_POST_ANSWER_RESULT_CUSTOMER';
export const UPDATE_EMAIL_CUSTOMER_SUCCESS = 'UPDATE_EMAIL_CUSTOMER_SUCCESS';
export const UPDATE_EMAIL_CUSTOMER_FAILURE = 'UPDATE_EMAIL_CUSTOMER_FAILURE';
export const VERIFY_TOKEN_CUSTOMER_SUCCESS = 'VERIFY_TOKEN_CUSTOMER_SUCCESS';
export const VERIFY_TOKEN_CUSTOMER_FAILURE = 'VERIFY_TOKEN_CUSTOMER_FAILURE';
// driver
export const UPDATE_PROFILE_DRIVER_SUCCESS = 'UPDATE_PROFILE_DRIVER_SUCCESS';
export const UPDATE_PROFILE_DRIVER_FAILURE = 'UPDATE_PROFILE_DRIVER_FAILURE';
export const POST_QUESTION_DRIVER_SUCCESS = 'POST_QUESTION_DRIVER_SUCCESS';
export const POST_QUESTION_DRIVER_FAILURE = 'POST_QUESTION_DRIVER_FAILURE';
export const CLEAR_POST_QUESTION_RESULT_DRIVER = 'CLEAR_POST_QUESTION_RESULT_DRIVER';
export const POST_SUGGESTION_DRIVER_SUCCESS = 'POST_SUGGESTION_DRIVER_SUCCESS';
export const POST_SUGGESTION_DRIVER_FAILURE = 'POST_SUGGESTION_DRIVER_FAILURE';
export const CLEAR_POST_SUGGESTION_RESULT_DRIVER = 'CLEAR_POST_SUGGESTION_RESULT_DRIVER';
export const EDIT_SUGGESTION_DRIVER_SUCCESS = 'EDIT_SUGGESTION_DRIVER_SUCCESS';
export const EDIT_SUGGESTION_DRIVER_FAILURE = 'EDIT_SUGGESTION_DRIVER_FAILURE';
export const CLEAR_EDIT_SUGGESTION_RESULT_DRIVER = 'CLEAR_EDIT_SUGGESTION_RESULT_DRIVER';
export const UPDATE_EMAIL_DRIVER_SUCCESS = 'UPDATE_EMAIL_DRIVER_SUCCESS';
export const UPDATE_EMAIL_DRIVER_FAILURE = 'UPDATE_EMAIL_DRIVER_FAILURE';
export const VERIFY_TOKEN_DRIVER_SUCCESS = 'VERIFY_TOKEN_DRIVER_SUCCESS';
export const VERIFY_TOKEN_DRIVER_FAILURE = 'VERIFY_TOKEN_DRIVER_FAILURE';
// prefecture
export const GET_PREF_LIST_SUCCESS = 'GET_PREF_LIST_SUCCESS';
export const GET_PREF_LIST_FAILURE = 'GET_PREF_LIST_FAILURE';

export const CLEAR_RESULT_FLG = 'CLEAR_RESULT_FLG';

// role
export const CHANGE_ROLE = 'CHANGE_ROLE';

// bulletin boards
export const GET_LIST_NOTIFICATIONS_SUCCESS = 'GET_LIST_NOTIFICATIONS_SUCCESS';
export const GET_LIST_NOTIFICATIONS_FAILURE = 'GET_LIST_NOTIFICATIONS_FAILURE';
export const GET_DETAIL_NOTIFICATIONS_SUCCESS = 'GET_DETAIL_NOTIFICATIONS_SUCCESS';
export const GET_DETAIL_NOTIFICATIONS_FAILURE = 'GET_DETAIL_NOTIFICATIONS_FAILURE';
export const POST_REPLY_SUCCESS = 'POST_REPLY_SUCCESS';
export const POST_REPLY_FAILURE = 'POST_REPLY_FAILURE';
export const REMOVE_BULLETIN_BOARD_SUCCESS = 'REMOVE_BULLETIN_BOARD_SUCCESS';
export const REMOVE_BULLETIN_BOARD_FAILURE = 'REMOVE_BULLETIN_BOARD_FAILURE';
export const REMOVE_BULLETIN_BOARD_RELY_SUCCESS = 'REMOVE_BULLETIN_BOARD_RELY_SUCCESS';
export const REMOVE_BULLETIN_BOARD_RELY_FAILURE = 'REMOVE_BULLETIN_BOARD_RELY_FAILURE';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE';
export const GET_DETAIL_NOTIFICATIONS_TOP_SUCCESS = 'GET_DETAIL_NOTIFICATIONS_TOP_SUCCESS';
export const GET_LIST_NOTIFICATIONS_TOP_FAILURE = 'GET_LIST_NOTIFICATIONS_TOP_FAILURE';
export const CLEAR_LIST_NOTIFICATIONS = 'CLEAR_LIST_NOTIFICATIONS';
//Top page
export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_FAILURE = 'SEND_CONTACT_FAILURE';
export const SEND_CONTACT_CLEAR = 'SEND_CONTACT_CLEAR';

//contents
export const GET_LIST_CONTENT_SUCCESS = 'GET_LIST_CONTENT_SUCCESS';
export const GET_LIST_CONTENT_FAILURE = 'GET_LIST_CONTENT_FAILURE';
export const GET_DETAIL_CONTENT_SUCCESS = 'GET_DETAIL_CONTENT_SUCCESS';
export const GET_DETAIL_CONTENT_FAILURE = 'GET_DETAIL_CONTENT_FAILURE';
// checkBadges
export const CUSTOMER_CHECK_BADGES_SUCCESS = 'CUSTOMER_CHECK_BADGES_SUCCESS';
export const CUSTOMER_CHECK_BADGES_FAILURE = 'CUSTOMER_CHECK_BADGES_FAILURE';
export const DRIVER_CHECK_BADGES_SUCCESS = 'DRIVER_CHECK_BADGES_SUCCESS';
export const DRIVER_CHECK_BADGES_FAILURE = 'DRIVER_CHECK_BADGES_FAILURE';
export const CLEAR_CHECK_BADGES = 'CLEAR_CHECK_BADGES';
//member supporting
export const GET_LIST_MEMBER_SUPPORTING_SUCCESS = 'GET_LIST_MEMBER_SUPPORTING_SUCCESS';
export const GET_LIST_MEMBER_SUPPORTING_FAILURE = 'GET_LIST_MEMBER_SUPPORTING_FAILURE';
export const CLEAR_LIST_SUPPORTING = 'CLEAR_LIST_SUPPORTING';
// driver member
export const GET_LIST_DRIVER_MEMBER_SUCCESS = 'GET_LIST_DRIVER_MEMBER_SUCCESS';
export const GET_LIST_DRIVER_MEMBER_FAILURE = 'GET_LIST_DRIVER_MEMBER_FAILURE';
export const DISABLE_DRIVER_MEMBER_SUCCESS = 'DISABLE_DRIVER_MEMBER_SUCCESS';
export const DISABLE_DRIVER_MEMBER_FAILURE = 'DISABLE_DRIVER_MEMBER_FAILURE';
